:root{
  --borderColor08 : rgba(0, 0, 0, 0.03);
  --borderColor03 : rgba(0, 0, 0, 0.02);
  --borderColor1 : rgba(0, 0, 0, 0.03);
  --borderColor4 : rgba(0, 0, 0, 0.2);

  .dark-mode{
    --borderColor08 : rgba(255, 255, 255, 0.06);
    --borderColor03 : rgba(255, 255, 255, 0.02);
    --borderColor1 : rgba(255, 255, 255, 0.05);
    --borderColor4 : rgba(255, 255, 255, 0.2);
  }
}



.grid-line-wrapper-none{
  opacity: 0;
}
.grid-line-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;

  .row {
    height: 100%;

    div.col:nth-child(2n + 1) {
      border-right: 1px solid var(--borderColor08);
    }
    div.col:nth-child(2n) {
      border-right: 1px solid var(--borderColor03);
    }
  }

  @media only screen and (min-width: 992px) {
    .row {
      div.col:nth-child(2n + 1) {
        border-right: none;
        // border-right: 1px solid var(--borderColor03);
      }
      div.col:nth-child(2n) {
        border-right: 1px solid var(--borderColor1);
      }
    }
  }
}
