.menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;

  .menu-content {
    height: calc(100vh - 100px);
  }

  ul.tab-titles {
    > li {
      position: relative;
      overflow: hidden;
      > a,
      > span {
        font-size: 10vw;
        line-height: 10vw;
        letter-spacing: -0.1vw;
        transition: var(--transition);
        animation: slideIn 0.6s ease-in-out 0.6s;
        animation-fill-mode: both;
        &:hover,
        &.active {
          color: var(--secondary);
          -webkit-text-stroke-color: transparent;
        }
        @media only screen and (min-width: 576px) {
          font-size: 8vw;
          line-height: 8vw;
        }
        @media only screen and (min-width: 768px) {
          font-size: 6vw;
          line-height: 6vw;
        }
        @media only screen and (min-width: 1200px) {
          font-size: 2.2vw;
          line-height: 2.2vw;
        }
      }

      .expand {
        position: absolute;
        right: 0;
        top: 0;
        height: 9.6vw;
        width: 10vw;
        font-size: 4.4vw;
        background: none;
        border: 1px solid var(--secondary);
        color: var(--secondary);
        @media only screen and (min-width: 576px) {
          height: 7.6vw;
          width: 8vw;
          font-size: 3vw;
        }
        @media only screen and (min-width: 768px) {
          height: 5.6vw;
          width: 6vw;
          font-size: 2vw;
        }
      }

      .video-wrapper:after {
        content: "Click to visit Portfolio page";
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        font-size: 1.4rem;
        line-height: 2rem;
        width: 120px;
        display: flex;
        padding: 1.8rem;
        align-items: center;
        height: 120px;
        transform: translate(-50%, -50%);
        border-radius: 100px;
      }
    }
  }

  .tab-detail {
    height: 0;
    transition: var(--transition);
    .list-title {
      font-size: 1.8rem;
      color: var(--secondary);
      font-family: var(--regularBoldFont);
      animation: slideIn 0.6s ease-in-out;
      animation-fill-mode: both;
      @media only screen and (min-width: 1200px) {
        font-size: 1.2rem;
      }
      @media only screen and (min-width: 1250px) {
        font-size: 1.25rem;
      }
      @media only screen and (min-width: 1300px) {
        font-size: 1.3rem;
      }
      @media only screen and (min-width: 1366px) {
        font-size: 1.4rem;
      }
      @media only screen and (min-width: 1500px) {
        font-size: 1.8rem;
      }
    }
    ul.ul-list {
      animation: slideIn 0.6s ease-in-out;
      animation-fill-mode: both;
      li {
        a {
          font-size: 1.4rem;
          font-family: var(--regularFont);
          @media only screen and (min-width: 1200px) {
            font-size: 1.2rem;
          }
          @media only screen and (min-width: 1250px) {
            font-size: 1.25rem;
          }
          @media only screen and (min-width: 1300px) {
            font-size: 1.3rem;
          }
          @media only screen and (min-width: 1366px) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  &.initial {
    .list-title,
    ul.ul-list {
      animation-delay: 1.2s;
    }
  }

  .animated-bars {
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -2;

    .bar {
      background-color: var(--white);
      animation: bar 1s ease-in-out;
      animation-fill-mode: both;

      &:nth-child(1) {
        animation-delay: 0s;
        @media only screen and (min-width: 1200px) {
          background-color: #090909;
        }
      }
      &:nth-child(2) {
        animation-delay: 0.1s;
        @media only screen and (min-width: 1200px) {
          background-color: #090909;
        }
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
      }
      &:nth-child(4) {
        animation-delay: 0.3s;
      }
      &:nth-child(5) {
        animation-delay: 0.4s;
      }
      &:nth-child(6) {
        animation-delay: 0.5s;
      }
    }
  }

  .secondary-menu {
    max-height: 100px;

    ul {
      animation: slideIn 0.6s ease-in-out 1.6s;
      animation-fill-mode: both;
      overflow:auto;
      li {
        a {
          white-space: nowrap;
        }
      }
    }
  }
}

@keyframes bar {
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-105%);
  }
  100% {
    transform: translateX(0);
  }
}
