header {
  &.dark-mode {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--white);
      height: 100%;
      width: 100%;
      z-index: -100;
      transition: var(--transition);
    }
  }
  .logo {
    margin: 10px 0 10px 0;

    img {
      width: 110px;
      aspect-ratio: auto 110 / 39;
      max-width: 100%;
      height: auto;
      @media only screen and (min-width: 576px) {
        width: 140px;
        max-width: 100%;
        aspect-ratio: auto 140 / 50;
        height: auto;
      }
    }

    @media only screen and (min-width: 992px) {
      margin: 15px;
    }
  }
}

.mi_hiring_main{
  @media (min-width: 1060px) {
    display: none !important;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1366px){
    .col-xl-2{
      flex: 0 0 25.66667% !important;
      max-width: 25.66667% !important;
    }
    .offset-xl-8{
      margin-left: 57.66667% !important;
    }
  }
  @media only screen and (min-width: 1367px) and (max-width: 1651px){
    .col-xl-2{
      flex: 0 0 21.66667% !important;
      max-width: 21.66667% !important;
    }
    .offset-xl-8{
      margin-left: 61.66667% !important;
    }
  }
  .mi_hiring{
    margin: 21px 15px;
    @media only screen and (min-width: 768px) and (max-width: 991px)  {
      margin: 11px 0;
    }
    .h_images{
      width: 32px;
      aspect-ratio: auto 36 / 32;
      max-width: 100%;
      height: auto;
    }
    .arrow_img{
      width: 21px;
      aspect-ratio: auto 18 / 21;
      max-width: 100%;
      height: auto;
    }
    .media-body{
      padding-left: 25px;
      h4{
        font-size: 1.4rem;
        line-height: 1.5rem;
        font-weight: 600;
        font-family: var(--robotoLight);
      }
      h5{
        font-size: 1.9rem;
        line-height: 2rem;
        font-weight: bold;
        font-family: var(--poppinsBold);
      }
    }
  }
}

