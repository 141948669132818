@font-face {
    font-family: 'robotoregular';
    src: url('roboto-regular-webfont.woff2') format('woff2'),
         url('roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'robotoRegularItalic';
    src: url('Roboto-Italic.woff2') format('woff2'),
    url('Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

