@font-face {
    font-family: 'PoppinsNormal';
    src: url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PoppinsMedium';
    src: url('Poppins-Medium.woff2') format('woff2'),
        url('Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

