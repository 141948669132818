:root {
  font-size: 62.5%;
  /*--transition: all 0.6s;*/
  --transition: transform 0.4s;
  --primary: #ED184F;
  /*--primary: #ff336a;*/
  --antiPrimary: #11e7b0;
  --secondary: #111111;
  --tertiary: #333333;
  --grey70: #707070;
  --greyCC: #cccccc;
  --white: #ffffff;
  --theme-black: #1A1A1A;
  --theme-black-secondary: #242424;
  --poppinsBold: "poppinsbold";
  --regularFont: "robotoregular";
  --regularBoldFont: "robotobold";
  --numbersFont: "ostrich_sansblack";
  --robotoLight: "Roboto";
  --poppinsLight:"Poppinslight";
  --PoppinsSemiBold:"Poppinssemibold";
  --PoppinsNormal:"Poppinsnormal";
  --PoppinsMedium:"Poppinsmedium";
  --poppins: "poppins";
  --robotoRegularItalic: 'robotoRegularItalic';
  --poppinsLight: "poppinsLight";
  @media only screen and (min-width: 992px) {
    //font-size: 1vw;
  }
  .dark-mode {
    --secondary: #f2f2f2;
    --white: #111111;
    --tertiary: #B2B2B2;
  }
}
.circle{
  will-change: transform;
}
$primary: #ff336a;
$antiPrimary: #11e7b0;
$secondary: #111111;
$tertiary: #333333;
$grey70: #707070;
$greyCC: #cccccc;
$white: #ffffff;

//-------------------------------------------//
//--------- imports from bootstrap ---------//
//-----------------------------------------//
@import "../node_modules/bootstrap/scss/bootstrap-grid";

//-------------------------------------------//
//-------------- font imports --------------//
//-----------------------------------------//
@import "assets/fonts/poppins-semibold/stylesheet.css";
@import "assets/fonts/poppins-light/stylesheet.css";
@import "assets/fonts/poppins-bold/stylesheet.css";
@import "assets/fonts/roboto-regular/stylesheet.css";
@import "assets/fonts/roboto-bold/stylesheet.css";
@import "assets/fonts/roboto-light/stylesheet.css";
@import "assets/fonts/poppins-normal/stylesheet.css";
@import "assets/fonts/poppins-light/stylesheet.css";
@import "assets/fonts/ostrich-sans-black/stylesheet.css";

//-------------------------------------------//
//-- custom variables and related classes --//
//-----------------------------------------//

//---- generate moon icons 122 ---- start //

@font-face {
  font-family: 'icomoon';
  src:  url('./assets/icomoon/fonts/icomoon.eot?w59ees');
  src:  url('./assets/icomoon/fonts/icomoon.eot?w59ees#iefix') format('embedded-opentype'),
  url('./assets/icomoon/fonts/icomoon.ttf?w59ees') format('truetype'),
  url('./assets/icomoon/fonts/icomoon.woff?w59ees') format('woff'),
  url('./assets/icomoon/fonts/icomoon.svg?w59ees#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
iframe{
  position: initial !important;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-right-arrow:before {
  content: "\e900";
  color: #fff;
}
.icon-left-arrow:before {
  content: "\e901";
  color: #fff;
}
//---- generate moon icons 122 ---- end //


.primary-text {
  color: var(--primary);
}

// Fonts
.poppins-bold {
  font-family: var(--poppinsBold);
}
.regularFont {
  font-family: var(--regularFont);
}
.regularBoldFont {
  font-family: var(--regularBoldFont);
}
.robotoLight{
  font-family: var(--robotoLight);
}
.poppinsLight{
  font-family: var(--poppinsLight);
}
.poppinsSemiBold{
  font-family: var(--PoppinsSemiBold);
}
.poppinsSemiBold{
  font-family: var(--PoppinsSemiBold);
}
.poppinsNormal{
  font-family: var(--PoppinsNormal);
}
.poppinsMedium{
  font-family: var(--PoppinsMedium);
}
.font-24{
  font-size: 2.4rem;
  line-height: 4.2rem;
  @media only screen and (max-width: 1600px) {
    font-size: 2.2rem;
    line-height: 3.6rem;
  }
  @media only screen and (max-width: 1400px) {
    font-size: 2rem;
    line-height: 3.4rem;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 1.8rem;
    line-height: 3rem;
  }
  @media only screen and (max-width: 576px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}

.next-text-white {
  color: #b2b2b2;
}

.numbers {
  color: var(--primary);
  font-family: var(--numbersFont);
}
//---------------------------------------//
//-- Common css for universal elements --//
//-------------------------------------//
* {
  margin: 0;
  padding: 0;
  outline: none;
}

::selection {
  background: var(--primary);
}

::-moz-selection { /* Code for Firefox */
  color: #FFFFFF;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--regularFont);
  padding-top: 6.6rem;
  background-color: #111;
  @media screen and (min-width: 1080px) {
    padding-top: 8rem; // For Fixed New Header
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
  color: inherit;
  &:hover{
    text-decoration: none;
    color: inherit;
  }
  //cursor: none;
}

ul {
  list-style: none;
}

section,
footer {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  /*@media only screen and (min-width: 992px){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }*/
  @media only screen and (min-width: 1200px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  @media only screen and (min-width: 1400px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
//----------------------------------------//
//---------- Universal classes ----------//
//--------------------------------------//
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.h-100 {
  height: 100%;
}
.transition {
  transition: var(--transition);
}
.uppercase-text {
  text-transform: uppercase;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.ul-list {
  list-style: none;

  > li {
    > * {
      padding-left: 2rem;
      position: relative;
      font-family: var(--regularBoldFont);
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      display: inline-block;
      font-size: 1.8rem;
      color: var(--secondary);
      @media only screen and (min-width: 768px) {
        font-size: 1.6rem;
      }
      @media only screen and (min-width: 992px) {
        font-size: 1.8rem;
        padding-left: 2.4rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 1.8rem;
      }
      @media only screen and (min-width: 1400px) {
        font-size: 2.2rem;
      }

      &:before {
        content: "";
        border: 1px solid var(--primary);
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        $sizes: 10px;
        width: $sizes;
        height: $sizes;
        @media only screen and (min-width: 992px) {
          $sizes: 16px;
          width: $sizes;
          height: $sizes;
        }
      }
    }
  }
}

.video-wrapper {
  position: relative;

  /*&:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }*/
}

// font-style classes
.outlined-support-text {
  color: var(--secondary);
  font-family: var(--poppinsBold);
}

.outlined-text {
  -webkit-text-stroke: 1px var(--secondary);
  color: var(--white);
  font-family: var(--poppinsBold);
  user-select: none;
  @media only screen and (min-width: 992px) {
    -webkit-text-stroke: 1.4px var(--secondary);
  }
}

.about-title {
  font-family: var(--poppinsBold);
  font-size: 2.8rem;
  letter-spacing: -0.1rem;
  line-height: 3.6rem;
  color: var(--secondary);

  @media only screen and (min-width: 576px) {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
  @media only screen and (min-width: 768px) {
    font-size: 3.2vw;
    line-height: 3.8vw;
    letter-spacing: -0.2vw;
  }
}

.about-text {
}
.roboto-24{
  /*font-size: 1.5rem;
  line-height: 2.5rem;*/
  color: var(--tertiary);
  font-family: 'robotoregular';
  font-size: 24px;
  line-height: 42px;
  font-weight: 300 !important;

}
.regular-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--tertiary);
  @media only screen and (min-width: 576px) {
    /*font-size: 1.6rem;
    line-height: 2.6rem;*/
  }
}
// Layout classes
.wrapper {
  min-height: 100%;
  background: var(--white);
  transition: var(--transition);
  overscroll-behavior-x: none;
  //cursor: none;
  /*@media only screen and (min-width: 992px) {
    padding-right: 8rem;
  }*/
}
.content-wrapper {
  position: relative;
  min-height: 100%;
  z-index: 0;
}
.scrolled{
  position: fixed;
  top: 0;
  left: 0;
  background-color: lightblue;
}

// Slick carousel
.carousel.slick-slider {
  .slick-list {
    //margin-bottom: 3rem;
  }

  ul.slick-dots {
    margin-top: 1.5rem;
    display: flex !important;
    @media only screen and (min-width: 576px) {
      margin-top: 2rem;
    }
    @media only screen and (min-width: 1200px) {
      margin-top: 3rem;
    }

    li {
      margin-right: 2rem;
      &:last-child {
        margin-right: 0;
      }
      button {
        width: 1.2rem;
        height: 1.2rem;
        font-size: 0;
        display: block;
        background: none;
        border-radius: 100%;
        border: 1px solid var(--grey70);
        &:before{
          top: -4px;
          left: -4px;
          content: '' !important;
        }
      }
      &.slick-active {
        button {
          border: 1px solid var(--primary);
          background: var(--primary);
        }
      }
    }
  }

}


.sub-header {
  font-size: 1.8rem;
  @media only screen and (min-width: 1200px) {
    font-size: 1.5rem;
  }
  @media only screen and (min-width: 1350px) {
    font-size: 1.8rem;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 2rem;
  }
  @media only screen and (min-width: 1650px) {
    font-size: 2.2rem;
  }
}

.new-sub-header{
  font-size: 3.6rem;
  line-height: 5rem;
  @media only screen and (max-width: 575px) {
    font-size: 2rem;
    line-height: 3rem;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    font-size: 3rem;
    line-height: 3.8rem;
  }
  @media only screen and (min-width: 1500px) and (max-width: 1699px) {
    font-size: 3rem;
    line-height: 4.4rem;
  }
}




.checkbox {
  input[type="checkbox"] {
    display: none;
    & + label {
      display: block;
      font-size: 1.8rem;
      @media only screen and (min-width: 768px) {
        font-size: 1.6rem;
      }
      @media only screen and (min-width: 992px) {
        font-size: 1.8rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 1.8rem;
      }
      @media only screen and (min-width: 1400px) {
        font-size: 2.2rem;
      }
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border: 1px solid #111111;
        display: inline-block;
        margin-right: 1.3rem;
      }
    }
    &:checked + label {
      &:before {
        border: 1px solid var(--primary);
        background: url("assets/imgs/check.svg") no-repeat center center / 13px
        var(--primary);
      }
    }
  }
}
.radio {
  input[type="radio"] {
    display: none;
    & + label {
      display: block;
      font-size: 1.8rem;
      @media only screen and (min-width: 768px) {
        font-size: 1.6rem;
      }
      @media only screen and (min-width: 992px) {
        font-size: 1.8rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 1.8rem;
      }
      @media only screen and (min-width: 1400px) {
        font-size: 2.2rem;
      }
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        border: 1px solid #111111;
        display: inline-block;
        margin-right: 1.3rem;
      }
    }
    &:checked + label {
      &:before {
        border: 1px solid var(--primary);
        background: var(--primary);
      }
    }
  }
}

.grey07-color {
  color: var(--grey70);
}

.sitemap-title {
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: -0.16vw;
  @media only screen and (min-width: 768px) {
    font-size: 3vw;
    line-height: 3.4vw;
  }
}

.active-class {
  color: red;
}

.color-primary {
  color: var(--primary);
}
.a-tag-link {
  a {
    color: var(--primary);
  }
  p {
    padding-top: 10px !important;
  }
}
.br-30{
  border-radius: 30px;
}
.client-img{
  width: 98px;
  height: 98px;
  border-radius: 50%;
}
.pink-button-new{
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: var(--white);
  border: 1px solid #d90a40;
  border-radius: 99em;
  display: inline-block;
  padding: 1.5rem 2.4rem;
  text-align: center;
  background-color: #d90a40;
  transition: all 0.5s;
  will-change: transform;
  &:hover{
    background-color: transparent;
    color: #111;
    border: 1px solid #fff;
    transition: all 0.5s;
  }
  img{
    width: 37px;
    aspect-ratio: auto 37 / 19;
    height: auto;
    max-width: 100%;
    @media only screen and (max-width: 767px) {
      width: 25px;
      aspect-ratio: auto 37 / 12;
    }
  }
  @media only screen and (max-width: 767px) {
    width: auto;
    font-size: 14px;
    line-height: 19px;
    padding: 12px 12px;
  }
}

.work-img{
  border-radius: 2rem;
}

#shadow-host-companion{
  display: none;
}