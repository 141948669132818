.mi-header-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 8rem;
  background: var(--secondary);
  z-index: 1;
  transition: .6s all;
  /*background: rgba(0, 0, 0, 0.8) !important;*/
  background: #111 !important;
  backdrop-filter: blur(18px) !important;
  --borderColor08: rgba(255, 255, 255, 0.08);
  --borderColor03: rgba(255, 255, 255, 0);
  --borderColor1: rgba(255, 255, 255, 0.05);
  --borderColor4: rgba(255, 255, 255, 0.4);
  @media (max-width: 1079px) {
    display: none !important;
  }
  .logo{
    /*padding-left: calc(100% / 6);*/
    img{
      /*width: 220px;*/
      height: 65px;
    }
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.nav-main {
  font-family: var(--poppinsLight);
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--white);
}

.nav-btn {
  align-self: center;
  white-space: nowrap;
  pointer-events: visible;
  background: var(--primary);
  color: var(--white);
  border-radius: 25px;
  height: 4rem;
  padding: 1rem 2rem 1.1rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-right: 3rem;

  &:hover {
    color: var(--white);
  }
}

.dropdown-padding {
  padding: 3rem;
}

.py-30 {
  padding: 3rem 0;
}

.pt-30 {
  padding-top: 3rem;
}

.pr-30 {
  padding-right: 3rem;
}

.pl-30 {
  padding-left: 3rem;
}

.dropdown-visit-more-text {
  font-family: var(--poppinsLight);
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: var(--white);
}

.dropdown-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width: 1079px) {
    width: 100%;
  }

  .nav-item-title {
    display: block;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--white);
    white-space: nowrap;
    /*&:hover{
      color: var(--primary);
    }*/
  }

  .nav-item-label {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: var(--white);
    opacity: 0.5;
    white-space: nowrap;
  }

  .nav-item-subtitle {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: var(--white);
  }

  .nav-item-ul-list {
    list-style: none;
    margin-bottom: 0;

    > li {
      > * {
        padding-left: 2rem;
        position: relative;
        font-family: var(--poppinsLight);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: inline-block;
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: var(--white);
        white-space: nowrap;

        &:hover {
          &:before {
            border: 1px solid var(--white);
          }
        }

        &:before {
          content: "";
          border: 1px solid var(--primary);
          border-radius: 100%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          $sizes: 12px;
          width: $sizes;
          height: $sizes;
        }
      }
    }
  }

  .dropdown-left-bottom-title {
    display: block;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--white);
    white-space: nowrap;
  }
}

/* Services Dropdown Section Start*/
.menu-svg-block {
  text-align: center;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-service-special-text-div {
  width: 24.3rem;
  @media screen and (max-width: 1079px) {
    width: auto;
  }
  @media screen and (max-width: 767px) {
    padding-top: 1.8rem;
  }

  .nav-service-special-text {
    font-family: var(--robotoRegularItalic);
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.dropdown-services {
  @media screen and (max-width: 767px) {
    display: block !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex-wrap: wrap;
  }
}

.dropdown-services-left {
  flex: 0 0 29%;
  padding: 0 3rem;
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 100%;
  }

  .dropdown-services-mobile-section{
    @media screen and (min-width: 577px) and (max-width: 1079px){
      display: flex;
    }
    .dropdown-services-mobile-section-left{
      @media screen and (min-width: 577px) and (max-width: 767px){
        flex: 0 0 50%;
        padding-right: 3rem;
        margin-bottom: 0 !important;
      }
      @media screen and (min-width: 768px) and (max-width: 1079px){
        flex: 0 0 33%;
        padding-right: 3rem;
        margin-bottom: 0 !important;
      }
    }
  }
}

.dropdown-services-center {
  flex: 0 0 46%;
  padding: 0 3rem;
  position: relative;
  @media screen and (max-width: 1079px) {
    padding-top: 2.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 67%;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: -10rem;
    bottom: -10rem;
    border-right: 1px solid var(--theme-black-secondary);
    @media screen and (max-width: 1079px) {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    top: -10rem;
    bottom: -10rem;
    border-right: 1px solid var(--theme-black-secondary);
    @media screen and (max-width: 1079px) {
      display: none;
    }
  }

  .dropdown-services-center-content {
    @media screen and (max-width: 576px) {
      display: block !important;
    }

    .dropdown-services-center-content-left {
      @media screen and (min-width: 577px) {
        padding-right: 3rem;
      }
      @media screen and (min-width: 577px) and (max-width: 1079px) {
        width: 51%;
      }
    }

    .dropdown-services-center-content-right {
      @media screen and (max-width: 576px) {
        padding-top: 2.5rem;
      }
    }
  }
}

.dropdown-services-right {
  flex: 0 0 25%;
  padding: 0 3rem;
  @media screen and (max-width: 1079px) {
    padding-top: 2.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    padding-top: 12.5rem;
  }

}

.dropdown-services-left-bottom {
  flex: 0 0 75%;
  padding: 2.8rem 3rem;

}

.dropdown-services-right-bottom {
  flex: 0 0 25%;
  padding: 2.8rem 3rem;
  @media screen and (max-width: 1079px) {
    display: none !important;
  }
}

.dropdown-services-bottom {
  color: var(--white);
  height: 10rem;
  @media screen and (max-width: 1079px) {
    height: auto;
    display: block !important;
  }

  .dropdown-services-left-bottom-graphics {
    @media screen and (max-width: 767px) {
      display: block !important;
    }
  }

  .bg-services-bottom-left {
    background: linear-gradient(90deg, #1d2671 0%, #c33764 100%);
  }

  .bg-services-bottom-right {
    background: var(--theme-black-secondary);
  }
}

/* Services Dropdown Section End*/

/* Solutions Dropdown Section Start*/
.dropdown-solutions{
  @media screen and (max-width: 576px){
    display: block !important;
  }
}

.dropdown-solutions-left {
  flex: 0 0 36%;
  padding: 0 3rem;
}

.dropdown-solutions-center {
  flex: 0 0 32%;
  padding: 0 3rem;
  position: relative;
  @media screen and (max-width: 576px){
    padding-top: 2.5rem;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: -10rem;
    bottom: -10rem;
    border-right: 1px solid var(--theme-black-secondary);
    @media screen and (max-width: 1079px) {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    top: -10rem;
    bottom: -10rem;
    border-right: 1px solid var(--theme-black-secondary);
    @media screen and (max-width: 1079px) {
      display: none;
    }
  }
}

.dropdown-solutions-right {
  flex: 0 0 32%;
  padding: 0 3rem;
  @media screen and (max-width: 751px){
    padding-top: 2.5rem;
  }
}

.dropdown-solutions-bottom {
  padding: 2rem 3rem;
  height: 6.1rem;
  color: var(--white);
  background: var(--theme-black-secondary);
}


.dropdown-solutions-left-bottom {
  flex: 0 0 68%;
  padding: 2.8rem 3rem;
  .solution-bottom-left-text{
    @media screen and (min-width: 1080px) {
      display: block;
    }
    @media screen and (max-width: 576px) {
      display: block;
    }
  }
  .dropdown-solution-contact-us{
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.dropdown-solutions-right-bottom {
  flex: 0 0 32%;
  padding: 2.8rem 3rem;
  @media screen and (max-width: 1079px) {
    display: none !important;
  }
}


/* Solutions Dropdown Section End*/

/* Hire Resources Dropdown Section Start*/
.dropdown-hire{
  @media screen and (max-width: 576px){
    display: block !important;
  }
  @media screen and (min-width: 577px) and (max-width: 1079px){
    flex-wrap: wrap;
  }
}

.dropdown-hire-left {
  flex: 0 0 36%;
  padding: 0 3rem;
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 33%;
  }
}

.dropdown-hire-center {
  flex: 0 0 33%;
  padding: 0 3rem;
  position: relative;
  @media screen and (max-width: 576px){
   padding-top: 2.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 33%;
  }
  @media screen and (min-width: 1080px) {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: -10rem;
      bottom: -10rem;
      border-right: 1px solid var(--theme-black-secondary);
      @media screen and (max-width: 1079px) {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      right: 0;
      top: -10rem;
      bottom: -10rem;
      border-right: 1px solid var(--theme-black-secondary);
      @media screen and (max-width: 1079px) {
        display: none;
      }
    }
  }
}

.dropdown-hire-right {
  flex: 0 0 31%;
  padding: 0 3rem;
  @media screen and (max-width: 820px) {
    padding-top: 2.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 33%;
  }
}

.dropdown-hire-left-bottom {
  flex: 0 0 36%;
  padding: 2.8rem 3rem;
  @media screen and (max-width: 767px) {
    padding: 2.5rem 3rem;
  }
}

.dropdown-hire-center-bottom {
  flex: 0 0 33%;
  padding: 2.8rem 3rem;
  border-right: 1px solid var(--theme-black-secondary);
  border-left: 1px solid var(--theme-black-secondary);
  border-top: 1px solid var(--theme-black-secondary);
  @media screen and (max-width: 767px) {
    padding: 2.5rem 3rem;
  }
}

.dropdown-hire-right-bottom {
  flex: 0 0 31%;
  padding: 2.8rem 3rem;
  @media screen and (max-width: 767px) {
    padding: 2.5rem 3rem;
  }
  @media screen and (max-width: 1079px) {
    display: none !important;
  }
}

.dropdown-hire-bottom {
  color: var(--white);
  height: 10rem;
  @media screen and (max-width: 1079px) {
    display: block !important;
    height: auto;
  }

  .dropdown-visit-more-hire {
    @media screen and (max-width: 1079px) {
      width: 12.6rem;
    }
  }

  .bg-hire-bottom-left {
    background: linear-gradient(90deg, #1d2671 0%, #c33764 100%);
  }

  .bg-hire-bottom-right {
    background: var(--theme-black-secondary);
  }
}

/* Hire Resources Dropdown Section End*/

/* About Dropdown Section Start*/
.dropdown-about {
  @media screen and (max-width: 576px) {
    display: block !important;
  }
}

.dropdown-about-left {
  flex: 0 0 42%;
  padding: 0 3rem;
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 33%;
  }
}

.dropdown-about-center {
  flex: 0 0 24%;
  padding: 0 3rem;
  @media screen and (max-width: 576px) {
    padding-top: 2.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 33%;
  }
}

.dropdown-about-right {
  flex: 0 0 33%;
  padding: 0 3rem;
  position: relative;
  @media screen and (max-width: 576px) {
    padding-top: 2.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1079px) {
    flex: 0 0 33%;
  }
  @media screen and (min-width: 1080px) {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: -10rem;
      bottom: -10rem;
      border-right: 1px solid var(--theme-black-secondary);
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      right: 0;
      top: -10rem;
      bottom: -10rem;
      border-right: 1px solid var(--theme-black-secondary);
    }
  }
}

.dropdown-about-bottom {
  padding: 2rem 3rem;
  height: 10.5rem;
  color: var(--white);
  /*background: linear-gradient(90deg, #00467f 0%, #a5cc82 100%);*/
  background: linear-gradient(90deg, #1d2671 0%, #c33764 100%);
}

.dropdown-about-bottom-title {
  font-size: 1.6rem;
  line-height: 2.4rem;
  white-space: nowrap;
}

.dropdown-about-bottom-text {
  width: 23.5rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--white);
  opacity: 0.5;
}

/* About Dropdown Section End*/

.top-space-h {
  margin-top: -10rem !important;
  transition: .9s all;
}

.top-space-b {
  margin-top: 0 !important;
  transition: .9s all;
}
