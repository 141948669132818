.label-box-main {
  &:hover {
    .nav-item-label {
      opacity: 1;
    }
  }
}

.hire-game-developers {
  &:hover {
    .nav-item-label {
      opacity: 1;
    }

    .nav-item-label {
      opacity: 1;
    }
  }
}

.bg-hire-bottom-right {
  a {
    span {
      @media only screen and (min-width: 1080px) {
        width: 135px;
      }
    }
  }
}


/*
.label-box-main:hover .hire-game-developers {
  color: var(--white);
  opacity: 0.5;
}

.label-box-main:hover .hire-game-developers:hover {
  opacity: 1 !important;
}
*/
