.mi-header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6.6rem;
  width: 100%;
  background: var(--secondary);
  z-index: 5;
  @media (min-width: 1080px) {
    display: none !important;
  }
  .logo{
    img{
      width: 180px;
      height: 28px;
    }
  }
}

.mobile-menu-dropdown {
  position: fixed;
  z-index: 5;
  top: 6.6rem;
  left: 0;
  right: 0;
  padding-top: 1px;
  background: var(--theme-black);
  height: calc(100vh - 6.6rem);
  @media (max-width: 1079px) {
    overflow-y: scroll;
  }

  .mobile-menu-dropdown-item {
    .mobile-menu-dropdown-title {
      padding-left: 3.5rem;
      font-family: var(--poppinsLight);
      font-size: 1.6rem;
      line-height: 2.4rem;
      width: 100%;
      height: 6rem;
      margin-top: 1px;
      margin-bottom: 1px;
      background: var(--theme-black-secondary);
      color: var(--white);
      display: flex;
      align-items: center;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        transition: .3s ease all;
        bottom: 0;
        left: 0;
        width: 0;
        height: 3px;
        z-index: 1;
        pointer-events: none;
        background: var(--primary);
      }
    }
    .mobile-menu-dropdown-title-active{
      position: relative;
      &::after {
        content: "";
        position: absolute;
        transition: .3s ease all;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        z-index: 1;
        pointer-events: none;
        background: var(--primary);
      }
    }

    .dropdown-arrow-block {
      min-width: 6.4rem;
      height: 6rem;
      width: 6.4rem;
      margin-left: 2px;
      background: var(--theme-black-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile-menu-contact-btn {
    outline: none !important;
    border: none;
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
    width: 32rem;
    height: 4.4rem;
    background: var(--primary);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--PoppinsNormal);
    font-weight: 400;
    color: var(--white);
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.menu-button {
  background: var(--theme-black-secondary);
  border: none;
  outline: none !important;
  width: 6.4rem;
  height: 100%;

  img {
    width: 24px;
    aspect-ratio: auto 24 / 24;
    max-width: 100%;
    height: auto;
  }
}

.section-home-services {
  transition: height 800ms;
}

.chevron-primary {
  transform: rotate(180deg);
  transition: 0.3s ease all;
  path {
    stroke: var(--primary);
  }
}

.chevron-white {
  transition: 0.3s ease all;
}
